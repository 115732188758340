
import React, { useEffect,useContext,useState } from "react";
import {  Button, Modal } from 'react-bootstrap';

import Context from '../../_helpers/context';
import functions from "../../_helpers/functions"


import StepEta from "../../forms/form_step_eta"
import StepCity from "../../forms/form_step_city"
import StepUsername from "../../forms/form_step_username"
import StepPsw from "../../forms/form_step_psw"
import StepEmail from "../../forms/form_step_email"
import StepPsw_Email from "../../forms/form_step_psw_mail"

import './offer1.css'
const lang = require('./lang/lang.json')
const config = require("../../../config.json"); 
const domain = window.location.hostname;

export default function Offer1() {

  const context = useContext(Context)

 
  const locale = context.lang
  const [bottoneFinale, setBottoneFinale] = useState(context.voluum_domain);
  const [errore, setErrore] = useState({});
  
  const setupDomande = [
    {
      tipologia:"form",
      step:"form_step_city",
      controllo_campo:["city"],
      nome_domanda:lang[locale].step_citta_titolo,
      domanda:lang[locale].step_citta_sottotitolo,
      sottodomanda:lang[locale].step_citta_titoletto,
      testo_bottone:lang[locale].bottone,
      pulsanti:1
    },
    {
      tipologia:"form",
      step:"form_step_eta",
      controllo_campo:["eta"],
      nome_domanda:lang[locale].step_eta_titolo,
      domanda:lang[locale].step_eta_sottotitolo,
      sottodomanda:lang[locale].step_eta_titoletto,
      testo_bottone:lang[locale].bottone,
      pulsanti:1
    },
    {
      tipologia:"form",
      step:"form_step_username",
      controllo_campo:["username"],
      nome_domanda:lang[locale].step_username_titolo,
      domanda:lang[locale].step_username_sottotitolo,
      sottodomanda:lang[locale].step_username_titoletto,
      testo_bottone:lang[locale].bottone,
      pulsanti:1
    },
    {
      tipologia:"form",
      step:"form_step_psw_mail",
      controllo_campo:["email","password"],
      nome_domanda:lang[locale].step_email_titolo,
      domanda:lang[locale].step_email_sottotitolo,
      sottodomanda:lang[locale].step_email_titoletto,
      testo_bottone:lang[locale].bottone_finale,
      pulsanti:1,
      terms:lang[locale].terms
    }
   
    
  ]
   

    const [step, setStep] = useState(0);
    const [domande, setDomande] = useState(setupDomande);
  

    useEffect(()  => {
        document.body.classList.add('offer1');
       
    });


   

  function renderStepForm(){

    return (<div className="domandaCheckbox">
     
      <img src={context.remoteLogo}/>
      
      <h2 class="titolo" dangerouslySetInnerHTML={{__html: domande[step].nome_domanda}}></h2>
      <hr/>
      {domande[step].domanda && (<h2 class="titolettoSelezioni" dangerouslySetInnerHTML={{__html: domande[step].domanda}}></h2>)}
      
      {domande[step].sottodomanda && (<h3 class="sottotitolettoSelezioni" dangerouslySetInnerHTML={{__html: domande[step].sottodomanda}}></h3>)}
      
         {scegliStep()}
      
    </div>)
  }


  function scegliStep(){

  
      switch(domande[step].step) {
        case 'form_step_eta':
          return <StepEta { ... errore}/>;
        case 'form_step_city':
          return <StepCity { ... errore}/>;
        case 'form_step_username':
          return <StepUsername { ... errore}/>;
        case 'form_step_psw':
          return <StepPsw { ... errore}/>;
        case 'form_step_email':
          return <StepEmail { ... errore}/>;
        case 'form_step_psw_mail':
          return <StepPsw_Email { ... errore}/>;
      
      }

  }


  const StepBody = () => {


    if(domande[step].tipologia == "form"){
      return renderStepForm()
    }
  }


  function controllaErroriCampi(){
    var campi_errati = { ...errore }
    var check = true
    for(let i=0;i<domande[step].controllo_campo.length;i++){
      if(!context.user_form[domande[step].controllo_campo[i]]){
        campi_errati[domande[step].controllo_campo[i]] = true
        setErrore(campi_errati)
        check = false
      }else{
        campi_errati[domande[step].controllo_campo[i]] = false
        setErrore(campi_errati)
      }
    }
    
    if(!check){
      return false
    }else{
      setErrore({})
      return true
    }
    
  }


  function StampaPulsanti(){
      if(domande[step].pulsanti == 1){
        return (<div className="contieniPulsanti">
        <Button onClick={() => { 
  
            const check = controllaErroriCampi()
              if(check){
                if(step !== Object.keys(domande).length -1 ){
                  setStep(prev => prev+1) 
                }else{
                  submitLead()
                }
              }
       
        }
          
        } className="unico">{domande[step].testo_bottone}</Button>

        {domande[step].terms && (<label className="terms"><input type="checkbox" defaultChecked={true}></input>{domande[step].terms}</label>)}
      </div>)
      }
    
    
    return <></>
  }

  

  async function submitLead(){
    var search = window.location.search.substring(1)
    
    if(search){
      const query = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
      context.user_form = {...context.user_form , ... query}
    }

    console.log(context.user_form )
    const res = await functions.submitLead(context.user_form)
    if(res.success){
      window.location.replace(res.red_url+"&"+window.location.search.replace('?', ''))
    }else{
      window.location.replace(res.red_url+"?"+window.location.search)
    }
  }

  

  useEffect(() => {

   try{
    if(window.deviceFingerprint){
      functions.trackUser(window.deviceFingerprint,context).then(response=>{
        if(response){
          setBottoneFinale(response)
        }
      })
    }
   }catch(e){
    console.log("Track user error - ",e)
   }  
    

  },[window.deviceFingerprint]); 



	return ( <div className="offer1" style={{height: window.innerHeight,background:'url("https://landing.jammydate.com/img/bk_off_1.jpg")',backgroundSize:'cover'}}>
    
    <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {return } }
      >
        <div className="contenitoreDomande">
        <Modal.Body>
            <div class="lpbody">
              <StepBody/>
            </div>
        </Modal.Body>
        <Modal.Footer>
           <StampaPulsanti/>
         </Modal.Footer>
         </div>
         <div className="footerDomande">
            <span></span>
            <ul>
              <li><a href={config[domain].privacy}>Privacy</a> -</li>
              <li><a href={config[domain].terms}>Terms and Conditions</a> - </li>
              <li><a href={config[domain].cookie}>Cookie</a></li>
            </ul>
          </div>
      </Modal>
     
   
      </div>);


}